var InteractionTypeImpl = InteractionType.extend({
  init: function(blueConicClient, context) {
    this.blueConicClient = blueConicClient;
    this.context = context ? context : null;

    this.USNAT_OBJECTIVE_PREFIX = "usnat_consent_";
    this.gpp_keys =  ["SaleOptOut", "SharingOptOut", "TargetedAdvertisingOptOut", "PersonalDataConsents"]
  },

  onPrepare: function() {
    this.addEventListener();
  },

  onLoad: function() {
    this.onLoad = true;
    this.addEventListener();
  },

  addEventListener: function() {
    if (window.__gpp && !this.loaded) {
      this.loaded = true;
      var me = this;
      window.__gpp("addEventListener", function(event) {
        if (!event || !event.pingData) {
          return;
        }

        me._handleConsent(event.pingData);
      });
    }
  },

  /**
   * Handler to convert the consent cookie to BlueConic objectives.
   *
   * @param {string} gppData
   * @param tcData
   */
  _handleConsent: function(gppData) {
    if (!gppData) {
      return;
    }

    var profile = this.blueConicClient.profile.getProfile();

    var gppString = gppData.gppString;
    if (gppString) {
      // set gppString as a profile property
      profile.setValue("gpp_string", gppString);
    }

    if (gppData.parsedSections) {
      for (var property in gppData.parsedSections) {
        this.setObjectives(property, gppData.parsedSections[property]);
      }
    }

    if (this.onLoad) {
      this.blueConicClient.profile.updateProfile();
    }
  },

  /**
   * Set Objectives
   * @param {*} section
   * @param {*} data
   * @returns
   */
  setObjectives: function(section, data) {
    if (!section || !data) {
      return;
    }

    if (section === "usnat" || section === "usnatv1") {
      this.setUsnat(data);
    }
  },

  /**
   * Set USNAT Objectives
   * 
   *  SaleOptOut | Opt-Out of the Sale of the Consumer’s Personal Data
      SharingOptOut | Opt-Out of the Sharing of the Consumer’s Personal Data
      TargetedAdvertisingOptOut | Opt-Out of Processing the Consumer’s Personal Data for Targeted Advertising
      SensitiveDataProcessing**
      ** (1) Consent to Process the Consumer’s Sensitive Data Consisting of Personal Data Revealing Racial or Ethnic Origin. 
      ** (2) Consent to Process the Consumer’s Sensitive Data Consisting of Personal Data Revealing Religious or Philosophical Beliefs. 
      ** (3) Consent to Process the Consumer’s Sensitive Data Consisting of Personal Data Concerning a Consumer’s Health (including a Mental or Physical Health Condition or Diagnosis; Medical History; or Medical Treatment or Diagnosis by a Health Care Professional).
      ** (4) Consent to Process the Consumer’s Sensitive Data Consisting of Personal Data Revealing Sex Life or Sexual Orientation.
      ** (5) Consent to Process the Consumer’s Sensitive Data Consisting of Personal Data Revealing Citizenship or Immigration Status.
      ** (6) Consent to Process the Consumer’s Sensitive Data Consisting of Genetic Data for the Purpose of Uniquely Identifying an Individual / Natural Person.
      ** (7) Consent to Process the Consumer’s Sensitive Data Consisting of Biometric Data for the Purpose of Uniquely Identifying an Individual / Natural Person.
      ** (8) Consent to Process the Consumer’s Sensitive Data Consisting of Precise Geolocation Data.
      ** (9) Consent to Process the Consumer’s Sensitive Data Consisting of a Consumer’s Social Security, Driver’s License, State Identification Card, or Passport Number.
      ** (10) Consent to Process the Consumer’s Sensitive Data Consisting of a Consumer’s Account Log-In, Financial Account, Debit Card, or Credit Card Number in Combination with Any Required Security or Access Code, Password, or Credentials Allowing Access to an Account.
      ** (11) Consent to Process the Consumer’s Sensitive Data Consisting of Union Membership.
      ** (12) Consent to Process the Consumer’s Sensitive Data Consisting of the contents of a Consumer’s Mail, Email, and Text Messages unless You Are the Intended Recipient of the Communication.

      PersonalDataConsents | Consent to Collection, Use, Retention, Sale, and/or Sharing of the Consumer’s Personal Data that Is Unrelated to or Incompatible with the Purpose(s) for which the Consumer’s Personal Data Was Collected or Processed 
   */
  setUsnat: function(data) {

    if (!data) {
      return;
    }

    if(Array.isArray( data )){
      data = data[0];
    }
    var profile = this.blueConicClient.profile.getProfile();

    var usnat = data;
    for (var key of this.gpp_keys) {
      if (usnat[key] && usnat[key] !== 0) {
        var objective = this.USNAT_OBJECTIVE_PREFIX + key.toLowerCase();
        usnat[key] === 2 ? profile.addConsentedObjective(objective) : profile.addRefusedObjective(objective);
      }
    }
    var sensitiveDataKey = "SensitiveDataProcessing";
    var sensitiveDataProcessing = usnat[sensitiveDataKey];
    if (sensitiveDataProcessing) {
      for (var i = 0; i < sensitiveDataProcessing.length; i++) {
        var value = sensitiveDataProcessing[i];
        if (value && value !== 0) {
          var objective = this.USNAT_OBJECTIVE_PREFIX + sensitiveDataKey.toLowerCase() + "_" + (i + 1);
          value === 2 ? profile.addConsentedObjective(objective) : profile.addRefusedObjective(objective);
        }
      }
    }
  }
});
